<template>
  <div class="fras_pagos mr-1">   
    <v-dialog v-model="dialog" content-class="modal" persistent> 
      <!-- Botón -->
      <template v-slot:activator="{on}">                

        <v-btn fab depressed x-small 
          :color="!show? '' : pagada ? 'green lighten-3' : 'red lighten-3'"
          v-on='on'
          @click="pagos"
          v-bind="$cfg.btra_cfg.standard"
          title='Pagos Factura'
          :disabled="!show">            
          <div style="color:white;font-weight:bold;font-size:1rem"> P </div>
          <sub style="color:blue;font-size:1rem;"> F</sub>
        </v-btn>

      </template>

      <!-- Formulario Modal -->
      <div class="contenedor centrado">
        <v-sheet :elevation="4">        
                    
          <!-- Cabecera -->
          <div class="cabecera">
            <base_Header
              v-if="Entorno.header.header"       
              :Entorno="Entorno.header"
              @onEvent="dialog=false">
            </base_Header>
          </div>           
          
          <!-- Contenido -->
          <div class="contenedor" style="width:600px"> 
            <div class="columna">
              <div class="conflex">
                <v-btn 
                  v-bind="$cfg.btra_cfg.standard"
                  title="Cerrar"
                  @click="salir">
                    <v-icon>{{'mdi-close'}}</v-icon>
                </v-btn>      
                <v-btn 
                    v-show="Number($store.state.G.USUDAT.ndep)>=7"               
                    v-bind="$cfg.btn.busca_with_text"             
                    @click="pagoFactura"
                    :disabled="selected.length!=1"
                    title="Pago Factura *Directo">
                     + PFD
                 </v-btn>                                         
              </div>
              
              <vllabel        
                style="width:100%;margin-top:10px"        
                label="Cuenta"            
                :txt="FRA.name_cta_id">
              </vllabel>

              <div class="conflex">
                <vllabel    
                  style="width:100%"             
                  label="Factura"            
                  :txt="FRA.fra_doc">
                </vllabel>
              </div>
              <div class="conflex">          

                <vllabel 
                  style="width:50%"                
                  label="Fecha"            
                  :txt="FRA.fra_fh|fecha">
                </vllabel>              
                <vllabel                    
                  style="width:50%"            
                  label="Importe"            
                  :txt="FRA.imp|num(2)">
                </vllabel>  
              </div>
               <div class="conflex">
                <vllabel                    
                  style="width:50%"            
                  label="Pagado"            
                  :txt="FRA.fra_pago|num(2)">
                </vllabel>
               
                <vllabel                    
                  style="width:50%"            
                  label="Pendiente"            
                  :txt="FRA.pte|num(2)">
                </vllabel>          
                <!-- <v-text-field                   
                  class="green lighten-5"
                  v-bind="$input"
                  v-model="schema.ctrls.pte.value"
                  label="a PAGAR (parcial)">
                </v-text-field> -->
              </div>    
              <hr class="mt-1">

              <!-- {{ accionRow }} -->
              
              <!-- grid pagos ------------------------------------------------------>                          
              <base_Fgrid
                :padre="stName" 
                :Entorno="Entorno"  
                height="200px"        
                :items-per-page=-1 
                hide-default-footer 
                fixed-header     
                dense       
                v-model="selected"
                show-select         
                single-select >
          
              </base_Fgrid>
              <!-- {{ R }} -->
              <!-- <hr class="mb-1"> -->

              <div v-show="R.id>0">
                <div class="conflex">
                  <vllabel    
                      style="width:33%"             
                      label="IdPago"            
                      :txt="R.id">
                  </vllabel>
                  <vllabel    
                      style="width:33%"             
                      label="Fecha"            
                      :txt="R.fhp|fecha">
                  </vllabel>
                  <vllabel    
                      style="width:34%"             
                      label="Importe"            
                      :txt="R.imp">
                  </vllabel>
                </div>
                <div class="conflex">
                  <vllabel    
                      style="width:40%"             
                      label="Contab."            
                      :txt="R.conta_fh|fechahr">
                  </vllabel>                
                  <vllabel    
                      style="width:30%"             
                      label="Ope"            
                      :txt="R.conta_usu">
                  </vllabel>                
                  <vllabel    
                      style="width:30%"             
                      label="Tram/Asi"            
                      :txt="R.asi">
                  </vllabel> 
                <v-btn 
                  v-show="R.rem=='0'"
                  v-bind="$cfg.btn.busca_with_text" 
                  @click="pago_cancelar">Cancelar                
                  <!-- <v-icon right dark>{{ $cfg.btn.busca_with_text.icono }}</v-icon> -->
                </v-btn>
                <button @dblclick="pago_eliminar" 
                  v-show="Number($store.state.G.USUDAT.ndep)>=8" 
                  style="text-align:right;color:maroon">
                  <i><small>* Eliminar</small></i>
                </button>                  
                <button @dblclick="pago_contabilizar" class="pl-2"
                  v-show="Number($store.state.G.USUDAT.ndep)>=8" 
                  style="text-align:right;color:maroon">
                  <i><small> ** Contabilizar</small></i>
                </button>                  
                
              </div>
              </div>

            </div>
          </div>
        </v-sheet> 
      </div>
      <div>
        <!-- Modal dinámica  -->
        <v-dialog
          v-model="modal"
          content-class="modal"
          persistent>
            <div class="centrado">
              <component                 
                  :is="componente_dinamico"
                  :padre="stName"                  
                  :accionRow="accionRow"                  
                  :disparo="disparo" 
                  :disparoFinder="disparo"               
                  @onEvent="event_modal"
              ></component>           
            </div>
        </v-dialog>         
      </div>
  
   </v-dialog>    
  </div>
</template>


<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
  const cobros_pagos_directoFra = () => plugs.groute("cobros_pagos_directoFra.vue", "comp");
 
  export default {
    
    mixins: [mixinFinder],
    components: { base_Header, base_Fgrid, cobros_pagos_directoFra },
    props: {
      padre: { type:String, default: '' },    
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: null }, 
      show: { type: [String, Boolean], default: "1" },    
      componenteTipo: { type:String, default:'M' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {          
            pte: { f:'pte', label:'Pendiente', value:""}
          }
        },
        api:'',
        stName:'stFfras_pagos',           
        headers: {},       

        dialog:false,

        componente_dinamico:null,
        modal:false,
        disparo:false,

        R: {},
      }    
    },

    created() {
      this.ini_data();

    },

    computed: {
      selected: {
        get() {    
          //if (typeof this.miSt=== 'undefined') return [];
          //if (!this.miSt.records.length) return [];
          //return [this.miSt.records[0]];  
          return [this.R];      
        },

        set(item) { 
          if (!item.length) {
            this.R= { id: 0 };
            //this.botoneras_set();
            return;
          } 

          console.log('R: ', item[0]);
          this.R= item[0];
          //this.items_frasEmail= this.get_record_fras_Exp_email();
          //this.botoneras_set();
        }
      },

      FRA() {
        if (this.accionRow==null) return {};

        let R=this.accionRow;
        R.pte=R.imp-R.fra_pago;
        return R;
      },

      pagada() {
        return Number(this.FRA.fra_pago)!=0 ? true : false;
      }

    },
 

    methods: {

      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // configuro Entorno 
        this.Entorno.header.titulo = "FACTURA - PAGOS";              
                
        //
        this.headers = {
          header: [
            { text: "Fecha", value: "fhp", width: "20%", filtro:'fecha' },
            { text: "Remesa", value: "rem", width: "15%" },
            { text: "Pago", value: "fpa", width: "25%" },
            { text: "Importe", value: "imp", width: "25%", filtro:'num' },
            { text: "Asiento", value: "conta_asi", width: "15%" },
         ],
        };

        this.Entorno.grid.headers = this.set_headers();
      },
      
      //async ini_component() { }
     
      async pagos() {        

        // call API
        let args = { tipo: 'fnc', accion: 'facturas', fn_args: { accion: "pagos", fnt: 'get', fra: this.accionRow.id }};         
        let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
        console.log('*get_records',apiResult);

        // actualizo registros del Grid
        this.$store.commit(this.stName + '/update_records', apiResult.r[0]);

        this.selected={};

      },  

      salir() {
        //this.$emit('refrescar', { id: this.accionRow.id } );
        //this.$emit('onEvent', { accion:'cerrar' });        

        this.$emit('reload');
        this.dialog= false;
      },

      //eliminar pago
      pago_eliminar() {
        this.$root.$alert.open('ELIMINAMOS PAGO?<br>ID: <b>' + this.R.id + '</b><br>Importe: <b>' + this.R.tot + '</b><br>Asiento: <b>' + this.R.conta_asi + '</b><br><br>', 'confirm')
        .then(r=> { if (r) this.eliminamos(); });
      },

      async eliminamos() {
        let args = { tipo:'fnc', accion: 'facturas', fn_args: { accion:'pagos', fnt: 'set', idP: -this.R.id }};
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Pago eliminado', 'success', 1800);

        this.actualizar();
      },  

      //contabilizar
      pago_contabilizar() {
        this.$root.$alert.open('CONTABILIZAMOS PAGO?<br>ID: <b>' + this.R.id + '</b><br>Importe: <b>' + this.R.tot + '</b><br>Asiento: <b>' + this.R.conta_asi + '</b><br><br>', 'confirm')
        .then(r=> { if (r) this.contabilizamos(); });
      },

      async contabilizamos() {
        let args = { tipo:'fnc', accion: 'contawin', 
          fn_args: { accion:'pago', idP: this.R.id, rec: 1 }
        };
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Pago Contabilizado', 'success', 1800);

        this.actualizar();
      },  
      
      pago_cancelar() {
        this.$root.$alert.open('CANCELAMOS PAGO ?<br>ID: <b>' + this.R.id + '</b><br>Importe: <b>' + this.R.tot + '</b><br>Asiento: <b>' + this.R.conta_asi + '</b><br>' +
          '<br>*  En caso de no estar TRAMITADO (2021)<br> Se eliminará el registro/asiento en caso contrario se genera la devolución del mismo' +
          '<br>** Si Pago es multiple facturas, se cancelan todas las facturas incluidas en el Pago<br><br>'
          , 'confirm')
        .then(r=> { if (r) this.cancelamos(); });
      },

      async cancelamos() {        

        if ((this.R.asi==0||this.R.asi=='') && this.R.fh>'2021') {
          this.eliminamos();
          return;
        }

        let args = { tipo:'fnc', accion: 'facturas', 
          fn_args: { accion:'pagos', fnt: 'cancelar', idP: this.R.id }
        };        
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Pago Cancelado', 'success', 1800);

        this.actualizar();
      },

      // actualizamos facturas padre y seleccionamos de nuevo
      actualizar() {        
        this.$emit('refrescar', { id: this.accionRow.id } );        
        this.ini_component();
      }, 

      //
      pagoFactura() {
        //this.accionRow= this.R;
        this.componente_dinamico="cobros_pagos_directoFra";
        this.modal=true;
      },

      event_modal(e) {
        //alert(e);

        if (e.act) this.actualizar();
                
        this.modal= false;
        this.componente_dinamico= null;
      },


    }
  };
</script>
